import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useState } from "react"
import logo from "../images/taxi-hans-logo.svg"
import { Transition } from "@headlessui/react"
import { StaticImage } from "gatsby-plugin-image"
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
  BriefcaseIcon,
  CheckIcon,
  GiftIcon,
  LibraryIcon,
  OfficeBuildingIcon
} from '@heroicons/react/outline'

const features = [
  { name: 'Event Shuttle Service', icon: GiftIcon, description: 'Größere Feier / Veranstaltungen für Privat oder für Ihr Geschäft. Mit unseren Großraumfahrzeugen (8 – 50 Sitzplätzen) können wir Ihre Gäste sicher und problemlos befördern.' },
  { name: 'Krankenbeförderung', icon: OfficeBuildingIcon, description: 'Wir führen Krankenfahrten sitzend oder liegend und mit Rollstuhl durch. Wir haben Verträge mit allen Krankenkassen.' },
  { name: 'Airport-Shuttle-Service', icon: RefreshIcon, description: 'Wir bringen bzw. holen Sie ab vom Airport. Damit Ihr Urlaub schon entspannt anfängt: keine Parkplatzprobleme, keine überteuerten Garagengebühren, keine Hetze in letzter Minute. ' },
  { name: 'Kongress-Fahrten', icon: BriefcaseIcon, description: 'Taxi Hans führt diverse Kongress-Fahrten durch Ärztekongresse fahren wir seit 2014 von diversen Airports zu den Hotels.' },
  { name: 'Schulbusfahrten', icon: LibraryIcon, description: 'Immer sicher, behütet und rechtzeitig in der Schule. Schülerbeförderungen führen wir im Auftrag von der jeweiligen Gemeinde als sogenannten Schulbusverkehr durch.' },
  { name: 'Merkmale', icon: CheckIcon, description: 'Kleine Gruppe, Familienfeier oder Businessevent – Wir planen und gestalten Ihre Fahrt komplett flexibel nach Ihren Wünschen.' },
]

export default function Work() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-gray-600 uppercase">Taxi Hans - Chieming</h2>
        <p className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Service Leistungen
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        Egal, ob Sie privat oder geschäftlich unterwegs sind. Wir sorgen mit perfektem Service dafür, dass Sie Ihr Ziel pünktlich und entspannt erreichen.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="h-full flow-root rounded-lg px-6 pb-8 bg-gray-100">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-gray-900 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-taxi" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-3xl font-bold text-gray-900 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-700">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
