import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from "../components/services"
import Work from "../components/work"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Work />
{/*
    <p>
    <StaticImage
      src="../images/taxi-hans-anytime-anywhere-2021.jpg"
      width={1200}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
    />
</p> */}
{/* 
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
 */}  
  </Layout>
)

export default IndexPage
